@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Axiforma-bold";
  src: url("./fonts/Axiforma-Bold.ttf");
}
@font-face {
  font-family: "Axiforma-medium";
  src: url("./fonts/Axiforma-Medium.ttf");
}

@font-face {
  font-family: "Poppins-medium";
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-bold";
  src: url("./fonts/Poppins/Poppins-Bold.ttf");
}

.formField {
  @apply rounded-[10px] outline-none w-full p-[18px] text-sm placeholder:text-darkGray tracking-wider;
}
.mapboxgl-ctrl,
.mapboxgl-ctrl-group {
  display: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.spotSelected svg {
  stroke: #3a85f4;
  stroke-width: 4px;
}
.hoverSpot svg {
  stroke: transparent;
  stroke-width: 4px;
}

.hoverSpot:hover svg {
  stroke: #3a85f4;
  stroke-width: 4px;
}
.scroll-transparent::-webkit-scrollbar {
  background: transparent;
}
.scroll-thin::-webkit-scrollbar {
  width: 6px;
}
.scroll-wide::-webkit-scrollbar {
  width: 11px;
}

.scroll-thumb-lightgray::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}
.scroll-thumb-white::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

.scroll-thumb-green::-webkit-scrollbar-thumb {
  background: #8a9683;
  border-radius: 10px;
}

.selectedProject {
  border: 3px solid #3a85f4;
}

body {
  overflow-x: hidden !important;
}
@layer utilities {
  @variants responsive {
    .stroke-current {
      stroke: currentColor;
    }
  }
}
