.react-tel-input .selected-flag {
	background: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
	background: none;
}

.react-tel-input .selected-flag:hover {
	background: none;
}

.react-tel-input .selected-flag.open {
	background: none;
}

.react-tel-input .flag-dropdown {
	border: none;
	background: none;
	background-color: none;
	padding-left: none;
}
.react-tel-input .flag-dropdown.open {
	background: none;
}

.react-tel-input .flag-dropdown.open .selected-flag {
	background: none;
}
